import React from 'react'
import PreHeader from '../components/PreHeader'
import Header from '../components/Header'
import Helmet from 'react-helmet'
import Footer from '../components/Footer'
import Wash1 from '../images/wash1.jpg'
import Wash2 from '../images/wash2.jpg'
import Wash3 from '../images/wash3.jpg'
import Wash4 from '../images/wash4.jpg'
import WashLogo from '../images/washLogo.png'
import Video from '../images/washVideo.jpg'
import Video2 from '../images/washVideo2.jpg'


export default () => (
  <div id="app" className="workpage">
      <Helmet>
          <meta charSet="utf-8" />
          <meta name="Description" content="WRAL Digital Solutions Refresh Little Washington Page"></meta>
          <title>Little Washington | WRAL Digital Solutions</title>
          <link rel="canonical" href="https://www.wraldigitalsolutions.com/little-washington/" />
        </Helmet>
      <PreHeader/>
      <Header/>
        <div className="company_heading">
          <span className="company_heading--name">WASHINGTON + WRALDS</span>
          <h1 className="company_heading--tagline">
          Telling the tales of a small town with a storied past.
          </h1>
        </div>
        <img className="company_gallery--image" src = {Wash1} alt = "little washington, nc "/>
        <div className="company_grid">
          <div className="company_overview">
            <div className="company_overview--heading">OVERVIEW</div>
            <p className="company_overview--text">
            Washington, North Carolina is nestled along the Pamlico and Tar Rivers.
            This waterfront town is as bustling as it is beautiful and offers an idyllic
            lifestyle for residents and visitors alike. Through a comprehensive native
            advertising campaign, we were able to tell Washington’s unique story. We did this through:
            </p>
            <ul className="company_overview--list">
              <li>Display Ad Creation</li>
              <li>Branded Content and Promotion</li>
              <li>Copywriting</li>
              <li>Custom Video Production</li>
              <li>Paid Social Media Advertising</li>
              <li>Branded Content</li>
            </ul>
          </div>
          <img className="company_logo" src = {WashLogo} alt ="progressive service company logo"/>

          <div className="company_gallery">
            <img className="company_gallery--image three" src = {Wash2} alt ="little washington "/>
            <img className="company_gallery--image one" src = {Wash3} alt ="little washington "/>
            <img className="company_gallery--image one" src = {Wash4} alt ="little washington "/>
          </div>
          <a href="https://vimeo.com/384020245" target="_blank" rel="noopener noreferrer">
            <img className="company_gallery--image video" src = {Video} alt ="little washington video "/>
          </a>
          <a href="https://vimeo.com/379344133" target="_blank" rel="noopener noreferrer">
            <img className="company_gallery--image video" src = {Video2} alt ="little washington video 2 "/>
          </a>
        </div>
    <Footer/>
  </div>
)
